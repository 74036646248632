<template>
  <div class="index-page">
    <el-container>
      <el-header>
      
      </el-header>
      <el-container>
        <el-aside width="200px">
          <el-menu :default-active="defaultActive">
            <el-menu-item v-for="menu of menus" :index="menu.id" :key="menu.id" @click="handleClick(menu.to)">
              <i :class="menu.menuIcon" />
              <span slot="title">{{ menu.menuName }}</span>
            </el-menu-item>
          </el-menu>
        </el-aside>
        <el-main>
          <router-view />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        menus: [
          { id: '1', menuName: '用户管理', menuIcon: 'el-icon-user', to: '/index/user' },
          { id: '2', menuName: '产品类型', menuIcon: 'el-icon-files', to: '/index/type' },
          { id: '3', menuName: '产品信息', menuIcon: 'el-icon-tickets', to: '/index/product' },
          { id: '4', menuName: '公司信息', menuIcon: 'el-icon-setting', to: '/index/company' },
          { id: '5', menuName: '轮播图片', menuIcon: 'el-icon-picture-outline', to: '/index/picture' },
          { id: '6', menuName: '关于我们', menuIcon: 'el-icon-info', to: '/index/about'},
          { id: '7', menuName: '新闻中心', menuIcon: 'el-icon-camera', to: '/index/news'},
          { id: '8', menuName: '人才招聘', menuIcon: 'el-icon-s-promotion', to: '/index/position'}
        ]
      }
    },
    computed: {
      defaultActive() {
        const { path } = this.$route
        const menu = this.menus.filter((obj) => obj.to === path)[0]
        return menu.id
      }
    },
    methods: {
      handleClick(to) {
        const currPath = this.$route.path
        if (to !== currPath) {
          this.$router.push(to)
        }
      }
    }
  }
</script>

<style lang="less">
  .index-page {
    height: 100%;
    
    .el-aside {
      box-shadow: 1px 0px 2px rgba(0, 0, 0, .1);
    }
    
    .el-menu {
      height: calc(100vh - 60px);
    }
    
    .el-header {
        box-shadow: 1px 1px 5px rgba(0, 0, 0, .1);
    }
  }
</style>
